<template>
  <div class="home-three">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.top.two }}</span>
      <span class="line"></span>
    </div>
    <div class="detail">
      <div class="ditem">
        <div class="_box">
          <img class="img" src="@/assets/images/check.png" />
        </div>
        <div class="stitle">{{ langvalue.section2.one }}</div>
        <div class="svalue">{{ langvalue.section2.two }}</div>
      </div>
      <div class="ditem">
        <div class="_box">
          <img class="img" src="@/assets/images/check.png" />
        </div>
        <div class="stitle">
          {{ langvalue.section2.three }}
        </div>
        <div class="svalue">{{ langvalue.section2.four }}</div>
      </div>
      <div class="ditem">
        <div class="_box">
          <img class="img" src="@/assets/images/check.png" />
        </div>
        <div class="stitle">
          {{ langvalue.section2.five }}
        </div>
        <div class="svalue">{{ langvalue.section2.six }}</div>
      </div>
      <div class="ditem">
        <div class="_box">
          <img class="img" src="@/assets/images/check.png" />
        </div>
        <div class="stitle">
          {{ langvalue.section2.seven }}
        </div>
        <div class="svalue">{{ langvalue.section2.eight }}</div>
      </div>
      <div class="ditem">
        <div class="_box">
          <img class="img" src="@/assets/images/check.png" />
        </div>
        <div class="stitle">
          {{ langvalue.section2.nine }}
        </div>
        <div class="svalue">{{ langvalue.section2.ten }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
const type = ref(langtype)
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    type.value = newVal
  },
  { immediate: true }
)
</script>
<style scoped lang="less">
.home-three {
  background: #f9f9f9;
  padding: 98px 100px;
  text-align: center;
}
.title {
  margin: 0 auto;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }
  .text {
    margin: 0 10px;
  }
}

.detail {
  width: 1340px;
  font-size: 20px;
  font-weight: 300;
  margin: 20px auto;
  padding: 100px 0 0;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  gap: 56px 56px;
  .ditem {

    background: #fff;
    box-sizing: border-box;
    padding: 67px 30px 49px;
    border-radius: 6px;
    ._box {
      background: #dce9e2;
      border-radius: 50%;
      margin: 0 auto;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .stitle {
      margin-top: 29px;
      font-size: 30px;
      font-weight: 500;
      color: #212529;
    }
    .svalue {
      color: #495057;
      font-size: 24px;
      padding-left: 22px;
      padding-top: 10px;
      text-align: left;
    }
  }
}
</style>
