<template>
  <div class="home-tol">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.top.nine }}</span>
      <span class="line"></span>
    </div>
    <div class="detail">
      <div class="ditem">
        <div class="stitle">
          {{ langvalue.section9.one }}
        </div>
        <br />
        <br />
        <div class="stitle">
          {{ langvalue.section9.two }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    const type = ref(langtype)
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))
    watch(
      () => store.state.home.type,
      newVal => {
        langvalue.value = returnLanguage(newVal)
        type.value = newVal
      },
      { immediate: true }
    )
    return { langvalue, type }
  }
}
</script>
<style scoped lang="less">
.home-tol {
  width: 100%;
  margin: 0 auto;
  padding: 100px 0 200px;
  // text-align: center;
  background: #F9F9F9;
}
.title {
  font-size: 40px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }
  .text {
    margin: 0 10px;
  }
}
.detail {
  width: 1240px;
  margin: 100px auto 0;

  display: flex;
  flex-wrap: wrap;
  text-align: left;
  .ditem {
    width: 100%;
    margin: 10px;
    .stitle {
      width: 1240px;
      margin: 0 auto;
      font-weight: 400;
      color: #000;
      font-family: "PingFang HK";
      font-size: 30px;
      line-height: 70px;
    }
  }
}
</style>
