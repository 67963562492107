<template>
  <div class="home-ele">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.top.eight }}</span>
      <span class="line"></span>
    </div>
    <div class="detail">
      <div class="itemlist">
        <div class="item">
          <div class="head"><img src="@/assets/images/wk_head.png" /></div>
          <div class="content">
            <div class="hname">
              <div class="name1">{{ langvalue.section8.one }}</div>
              <div class="name2">{{ langvalue.section8.two }}</div>
            </div>
            <div class="con_text">
              {{ langvalue.section8.three }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="head"><img src="@/assets/images/wk_head_1.png" /></div>
          <div class="content">
            <div class="hname">
              <div class="name1">{{ langvalue.section8.four }}</div>
              <div class="name2">{{ langvalue.section8.five }}</div>
            </div>
            <div class="con_text">
              {{ langvalue.section8.six }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="head"><img src="@/assets/images/wk_head_2.png" /></div>

          <div class="content">
            <div class="hname">
              <div class="name1">{{ langvalue.section8.seven }}</div>
              <div class="name2">{{ langvalue.section8.eight }}</div>
            </div>
            <div class="con_text">{{ langvalue.section8.nine }}</div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    const type = ref(langtype)
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))

    const playvideo = () => {
      document.getElementById('video').play()
    }
    const jiankongvideo = () => {
      document.getElementById('video').play()
    }
    watch(
      () => store.state.home.type,
      newVal => {
        langvalue.value = returnLanguage(newVal)
        type.value = newVal
      },
      { immediate: true }
    )
    return { langvalue, playvideo, jiankongvideo, type }
  }
}
</script>
<style scoped lang="less">
.home-ele {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 123px 0 70px;
  background: #eaebf0;
}
.title {
  font-size: 40px;
  font-weight: 400;
  color: #100e34;

  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }
  .text {
    margin: 0 10px;
  }
}
.stitle {
  color: #ffffff50;
  font-weight: 500;
  font-size: 18px;
  margin-top: 30px;
}
.detail {
  width: 1340px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 300;
  padding: 100px 0 0;
  .itemlist {
    display: flex;
    justify-content: space-between;
    .item {
      width: calc(33.3% - 40px);
      margin: 10px;
      border-radius: 20px;
      .head {
        display: flex;
        justify-content: center;
        img {
          width: 300px;
          height: 300px;
          vertical-align: middle;
        }
      }

      .content {
        padding: 33px 30px 47px;
        height: 413px;
        background: #fff;
        border-radius: 16px;
        .hname {
          // width: calc(100% - 80px);
          text-align: left;
          .name2 {
            font-size: 30px;
            color: #808080;
            font-family: 'PingFang HK';
            font-weight: 500;
          }
          .name1 {
            color: #111;
            font-family: 'PingFang HK';
            font-size: 50px;
            font-weight: 500;
          }
        }
        .con_text {
          margin-top: 20px;
          text-align: left;
          color: #999;
          text-align: justify;
          font-family: 'PingFang HK';
          font-size: 22px;
          font-weight: 400;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
