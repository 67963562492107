<script setup>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../assets/js/language'
// 我们的优势
import HomeThree from './components/home-three'
// 核心团队
import HomeEle from './components/home-ele'
// 企业愿景
import HomeTol from './components/home-tol';

import AboutBanner from './components/about-banner';

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
// console.log(langvalue.value.top.one, '测试')

const checkedtype = ref(langvalue.value.top.one)
const type = ref(langtype)
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).top.one
    type.value = newVal
  },
  { immediate: true }
)
</script>

<template>
  <div class="a_detail">
    <AboutBanner :checkedtype="checkedtype" title="ABOUT US" />
    <div class="center">
      <div class="c_cont">
        <div class="block_wrap">
          <div class="detail">
            <p>{{ langvalue.section1.one }}</p>
            <br />
            <p>{{ langvalue.section1.two }}</p>
            <br />
            <p>{{ langvalue.section1.three }}</p>
          </div>
          <div class="abimglist">
            <div class="avimgitem">
              <img src="https://resources.wukongbc.com/img/banner/1.jpg" />
            </div>
          </div>
        </div>
        <div class="c_img">
          <img class="left" src="../../assets/images/a_left.png" alt="">
          <img class="right" src="../../assets/images/a_right.png" alt="">
        </div>
      </div>
    </div>
    <HomeThree />
    <HomeEle />
    <HomeTol />
  </div>
</template>

<style scoped lang="less">
.a_detail {
  width: 100%;
  background: #F8F8F8;
 
  .center {
    box-sizing: border-box;
    width: 1540px;
    margin: 0 auto;
    padding: 160px 100px 103px;
    background: #fff;
    .c_cont {
      .block_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .detail {
          width: 697px;
          font-size: 28px;
          font-weight: 300;
          margin: 20px 0;
          padding: 20px 0 0 0;
          color: #000;
          font-weight: 400;
          // 左对齐
          text-align: left;
          .btn {
            margin-top: 30px;
            width: 172px;
            height: 55px;
          }
        }
        .abimglist {
          .avimgitem {
            width: 630px;
            height: 430px;
            img {
              width: 100%;
              height: 100%;
              // object-fit: cover;
              border-radius: 4px;
            }
          }
        }
      }

      .c_img {
        margin-top: 110px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        img {
          border-radius: 10px;

        }
        .left {
          width: 580px;
        }
        .right {
          width: 680px;
        }
      }
    }
  }
}
</style>
